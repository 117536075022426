import React, { useEffect, useMemo, useState } from "react";

import "../styles/MasterOtp.scss";
import ReactQuill from "react-quill";
import axios from "../utils/axios";
import AddIcon from "../assets/Icons/add.svg";
import MinusIcon from "../assets/Icons/minus.svg";
import Snackbar from "../components/common/Snackbar";

// Using imports from quill to convert into inline styles
const Align = ReactQuill.Quill.import("attributors/style/align");
const ColorClass = ReactQuill.Quill.import("attributors/class/color");
const SizeStyle = ReactQuill.Quill.import("attributors/style/size");

ReactQuill.Quill.register(Align, true);
ReactQuill.Quill.register(ColorClass, true);
ReactQuill.Quill.register(SizeStyle, true);

const initialBody = `Dear Candidate<br><br>Your OTP for HireQuotient Assessment is<br><br><strong>{{OTP}}</strong><br><br>This OTP is valid till {{Expiry Time}}.
`;

function MasterOtp() {
    const [to, setTo] = useState(null);
    const [bcc, setBcc] = useState("abhinavaditya162@gmail.com");
    const [cc, setCc] = useState("");
    const [subject, setSubject] = useState(
        "Your OTP for HireQuotient Assessment."
    );
    const [editorState, setEditorState] = useState(initialBody);
    const [otp, setOtp] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showCC, setShowCC] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [toast, setToast] = useState({ msg: "", open: false, type: null });

    // set expiry time to 11:59 PM
    const expiryTime = new Date();
    expiryTime.setHours(23, 59, 59);
    console.log("expiry time: ", expiryTime);

    useEffect(() => {
        setIsLoading(true);
        axios
            .get("/candidate/admin/otp")
            .then((res) => {
                setOtp(res?.data?.otp);
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    // for react quill
    const modules = useMemo(
        () => ({
            toolbar: [
                [{ header: [1, 2, 3, 4, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ align: [] }],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link"],
                [{ background: [] }],
            ],
        }),
        []
    );

    const formats = useMemo(
        () => [
            "header",
            "bold",
            "italic",
            "underline",
            "strike",
            "list",
            "bullet",
            "indent",
            "link",
            "image",
            "color",
            "background",
            "align",
        ],
        []
    );

    const handlePreEditorChange = (value) => {
        setEditorState(value);
    };

    const handleSend = (e) => {
        e.preventDefault();
        setIsSending(true);
        const payload = {
            to: to,
            bcc: bcc,
            cc: cc,
            subject,
            html: editorState
                ?.replace("{{OTP}}", otp)
                .replace("{{Expiry Time}}", expiryTime.toString()),
        };

        axios
            .post("/candidate/trigger-admin-otp", payload)
            .then((res) => {
                setIsSending(false);
                setToast({
                    msg: "Email sent successfully.",
                    open: true,
                    type: "success",
                });
                console.log(res.data);
            })
            .catch((err) => {
                setIsSending(false);
                setToast({
                    msg: "Failed to send email.",
                    open: true,
                    type: "error",
                });
                console.log(err);
            });
    };

    return (
        <div className="masterOtp__container">
            {/* Header */}
            <div className="masterOtp__header">Master OTP</div>

            {/* Body */}
            <div className="masterOtp__body">
                {/* Top Section*/}
                <div className="masterOtp__body__top" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                    <div className="masterOtp__body__top__heading">
                        Master OTP
                    </div>

                    <div className="masterOtp__body__top__otp">
                        {isLoading ? "Loading..." : otp}
                    </div>

                    <div className="masterOtp__body__top__validity">
                        This OTP is valid till {expiryTime.toString()}
                    </div>
                </div>

                {/* Bottom Section - currently hidden because of BCG Requirement change */}
                {/* <div className="masterOtp__body__bottom">
                    <form className="masterOtp__body__bottom__wrapper" onSubmit={handleSend}>
                        <div className="masterOtp__body__bottom__inputGroup">
                            <label htmlFor="to">To</label>
                            <input
                                value={to}
                                type="text"
                                id="to"
                                placeholder="Enter recipient's email address"
                                onChange={(e) => setTo(e.target.value)}
                                required
                            />
                        </div>

                        <div className="masterOtp__body__bottom__inputGroup">
                            <label htmlFor="bcc">Bcc</label>
                            <input
                                value={bcc}
                                type="text"
                                id="bcc"
                                placeholder="Enter bcc"
                                onChange={(e) => setBcc(e.target.value)}
                            />

                            <button className="masterOtp__body__bottom__inputGroup__addccBtn" type="button" title={`${!showCC ? "Add cc" : "Remove cc"}`}>
                                <img
                                    src={!showCC ? AddIcon : MinusIcon}
                                    alt="Add cc"
                                    height="20px"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowCC(!showCC);
                                    }}
                                />
                            </button>
                        </div>

                        {showCC && (
                            <div className="masterOtp__body__bottom__inputGroup">
                                <label htmlFor="cc">Cc</label>
                                <input
                                    value={to}
                                    type="text"
                                    id="cc"
                                    placeholder="Enter recipient's email address"
                                    onChange={(e) => setCc(e.target.value)}
                                />
                            </div>
                        )}

                        <div className="masterOtp__body__bottom__inputGroup">
                            <label htmlFor="subject">Subject</label>
                            <input
                                value={subject}
                                type="text"
                                id="subject"
                                placeholder="Enter subject"
                                onChange={(e) => setSubject(e.target.value)}
                                required
                            />
                        </div>

                        <div className="masterOtp__body__bottom__inputGroup">
                            <label htmlFor="body">Body</label>
                            <ReactQuill
                                theme="snow"
                                modules={modules}
                                formats={formats}
                                value={editorState}
                                onChange={handlePreEditorChange}
                                style={{
                                    height: "300px",
                                    maxWidth: "416px",
                                    marginBottom: "70px",
                                }}
                            />
                        </div>

                        <div className="masterOtp__body__bottom__buttonGroup">
                            <button type="submit" disabled={isSending}>
                                Send
                            </button>
                            <button
                                onClick={() => {
                                    setEditorState(initialBody);
                                }}
                            >
                                Reset
                            </button>
                        </div>
                    </form>
                </div> */}
            </div>

            <Snackbar
                open={toast.open}
                autoHideDuration={2500}
                onClose={() => setToast({ msg: "", open: false, type: null })}
                msg={toast.msg}
                type={toast.type}
            />
        </div>
    );
}

export default MasterOtp;
